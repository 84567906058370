import React, { Component } from 'react'
import Chart from "chart.js/auto";
import { lineWithErrorBars } from "chartjs-chart-error-bars"
import { obj } from "../data/3a"
import quotientIcon from '../images/amgen-blue.svg'
import clickIcon from '../images/click.png'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
let myLineChart;

const colors = [
    "#FFEB3C",
    "#17A3E3",
    "#9C28B1",
    "#409442",
    "#FF9700",
    "#019688",
    "#0E4779",
    "#AD1515",
    "#F22C1F",
    "#DE3998",
    "#01BCD5",
    "#5F37AB",
    "#8BC24A",
    "#277429",
    "#CDDC39",
    "#1478AD"
]


export default class LineGraph extends Component {
    
    

    chartRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            maindata: {},
            filterSelect: "All",
            countySelect: "",
            countrys: [],
            ages: [],
            sexs: [],
            sexSelect: "",
            ageSelect: "",
            showCountrys: false,
            infoBoxOpen: true,
            isOpenPopup: true
        };
        this.getRandomRgb = this.getRandomRgb.bind(this);
        this.getData = this.getData.bind(this);
    }

    getRandomRgb = () => {
        const num = Math.round(0xffffff * Math.random());
        const r = num >> 16;
        const g = num >> 8 & 255;
        const b = num & 255;
        console.log(lineWithErrorBars);
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        
    }

    getMissing = (a, b) => {
        let missings = [];
        let matches = false;

        for (var i = 0; i < a.length; i++) {
            matches = false;
            for (var e = 0; e < b.length; e++) {
                if (a[i] === b[e]) matches = true;
            }
            if (!matches) missings.push(a[i]);
        }
        return missings;
    }

    getData = () => {
        let dataset_filter = []
        let all_labels_filter = []
        let locations_filter = [];
        let countrys_filter = [];
        let ages_filter = [];
        let sexs_filter = [];
        let colorCount = 0;

        let newArray = obj.filter(function (el) {
            return el.Sex === "All" && el.Age === "All"
        });


        for (let i = 0; i < newArray.length; i++) {
            if (locations_filter.indexOf(newArray[i].COUN) === -1) {
                locations_filter.push(newArray[i].COUN);
            }
        }

        for (let i = 0; i < newArray.length; i++) {
            if (countrys_filter.indexOf(newArray[i].COUNTRY) === -1) {
                countrys_filter.push(newArray[i].COUNTRY);
            }
        }

        for (let i = 0; i < obj.length; i++) {
            if (ages_filter.indexOf(obj[i].Age) === -1) {
                ages_filter.push(obj[i].Age);
            }
        }

        for (let i = 0; i < obj.length; i++) {
            if (sexs_filter.indexOf(obj[i].Sex) === -1) {
                sexs_filter.push(obj[i].Sex);
            }
        }


        for (let i = 0; i < newArray.length; i++) {
            if (all_labels_filter.indexOf(newArray[i].YEAR) === -1) {
                all_labels_filter.push(newArray[i].YEAR);
            }
        }


        locations_filter.forEach(function (location) {
            let labels = [];
            let oos = [];
            let color = colors[colorCount];
            colorCount ++;
            newArray.forEach(function (report) {
                let TEMP = {}
                if (report.COUN === location) {
                    TEMP.y = report.DATA
                    TEMP.yMin = report.LOW
                    TEMP.yMax = report.HIGH
                    oos.push(TEMP)
                    labels.push(report.YEAR);
                }
            });

            let missing = this.getMissing(all_labels_filter, labels);

            if (missing) {
                missing.forEach(function (missed) {
                    labels.push(missed);
                    oos.push(0)
                });
            }


            let data = { label: location, data: oos, borderColor: color, backgroundColor: color }

            dataset_filter.push(data);

        }.bind(this));

        this.setState({
            maindata: { labels: all_labels_filter, datasets: dataset_filter },
            countrys: countrys_filter,
            ages: ages_filter,
            sexs: sexs_filter
        })

    }


    filterData = (filter, country) => {

        let dataset_filter = []
        let all_labels_filter = []
        let locations_filter = [];
        let colorCount = 0;

        if (filter === "All") {
            this.setState({
                filterSelect: "All",
                showCountrys: false,
                countySelect: this.state.countrys[0],
                sexSelect: this.state.sexs[0],
                ageSelect: this.state.ages[0]

            })
        }else if (filter === "AllSex") {
            this.setState({
                filterSelect: "AllSex",
                sexSelect: this.state.sexs[0],
                ageSelect: this.state.ages[0],
                countySelect: this.state.countrys[0],
                showCountrys: false
            })
        }else if (filter === "AllAge") {
            this.setState({
                filterSelect: "AllAge",
                sexSelect: this.state.sexs[0],
                ageSelect: this.state.ages[0],
                countySelect: this.state.countrys[0],
                showCountrys: false
            })
        }else if (country === "Comparision") {
            if (country == null){
                this.setState({
                    countySelect: this.state.countrys[0]
                })
            }
            
        }

        let newArray = obj.filter(function (el) {
            if (filter === "All" || filter === "AllSex" || filter === "AllAge") {
                return el.Sex === "All" && el.Age === "All"
            }else if (filter === "Age") {
                if(country === "Comparision"){
                        return el.COUNTRY === this.state.countrys[0] && el.Sex === "All"
                }else{
                    return el.Sex === "All" && el.Age === country
                }
            }else if (filter === "AgeCountry") {

                return el.Sex === "All" && el.COUNTRY === country
            }else if (filter === "Sex") {
                if(country === "Comparision"){
                        return el.COUNTRY === this.state.countrys[0] && el.Age === "All"
                }else{
                    return el.Age === "All" && el.Sex === country
                }
            }else if (filter === "SexCountry") {

                return el.Age === "All" && el.COUNTRY === country
            }
            return null
        }.bind(this));




        for (let i = 0; i < newArray.length; i++) {
            if (locations_filter.indexOf(newArray[i].COUN) === -1) {
                locations_filter.push(newArray[i].COUN);
            }
        }


        for (let i = 0; i < newArray.length; i++) {
            if (all_labels_filter.indexOf(newArray[i].YEAR) === -1) {
                all_labels_filter.push(newArray[i].YEAR);
            }
        }


        locations_filter.forEach(function (location) {
            let labels = [];
            let oos = [];
            let color = colors[colorCount];
            colorCount ++;
            newArray.forEach(function (report) {
                let TEMP = {}
                if (report.COUN === location) {
                    TEMP.y = report.DATA
                    TEMP.yMin = report.LOW
                    TEMP.yMax = report.HIGH
                    oos.push(TEMP)
                    labels.push(report.YEAR);
                }
            });

            let missing = this.getMissing(all_labels_filter, labels);

            if (missing) {
                missing.forEach(function (missed) {
                    labels.push(missed);
                    oos.push(0)
                });
            }


            let data = { label: location, data: oos, borderColor: color, backgroundColor: color }

            dataset_filter.push(data);

        }.bind(this));

        this.setState({
            maindata: { labels: all_labels_filter, datasets: dataset_filter }
        })

        // myLineChart.update();
    }

    componentDidMount() {

        this.getData();
        this.buildChart();
        
    }

    componentDidUpdate() {
        this.buildChart();
    }




    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");

        if (typeof myLineChart !== "undefined") myLineChart.destroy();
        Chart.defaults.font.size = 18;
        
        myLineChart = new Chart(myChartRef, {
            type: "lineWithErrorBars",
            data: this.state.maindata,
            options: {
                spanGaps: false,
                interaction: {
                    //mode: 'index',
                    intersect: false,
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            // usePointStyle: true,
                            boxWidth: 40,
                            boxHeight: 17,
                            font: {
                                size: 15,
                                weight: 500
                            },
                            padding: 15
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Year',
                            padding: 5,
                            font: {
                                size: 20,
                                weight: 700
                            }
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Incidence per 100,000 individuals',
                            padding: 5,
                            font: {
                                size: 20,
                                weight: 700
                            }
                        }
                    }
                },
            }

        });
    }

    HandleCountryChange = (e) => {

        if (this.state.filterSelect === "AllAge"){
            this.setState({
                countySelect: e.target.value
            }, () => this.filterData("AgeCountry", e.target.value))
        }else if (this.state.filterSelect === "AllSex"){
            this.setState({
                countySelect: e.target.value
            }, () => this.filterData("SexCountry", e.target.value))
        }
        
    }

    HandleAgeChange = (e) => {

        if(e.target.value === "Comparision"){
            
            this.setState({
                ageSelect: e.target.value,
                showCountrys: true,
                countySelect: this.state.countrys[0]

            }, () => this.filterData("Age", "Comparision"))

        }else{
            this.setState({
                ageSelect: e.target.value,
                showCountrys: false,
                countySelect: this.state.countrys[0]
            }, () => this.filterData("Age", e.target.value))
        }


    }

    HandleSexChange = (e) => {

        if(e.target.value === "Comparision"){
            
            this.setState({
                sexSelect: e.target.value,
                showCountrys: true,
                countySelect: this.state.countrys[0]

            }, () => this.filterData("Sex", "Comparision"))

        }else{
            this.setState({
                sexSelect: e.target.value,
                showCountrys: false,
                countySelect: this.state.countrys[0]
            }, () => this.filterData("Sex", e.target.value))
        }


    }

    showCountrys = () => {
        if (this.state.showCountrys === true) {
          return (<select className="form-control minimal" value={this.state.countySelect} onChange={this.HandleCountryChange} id="countySelectFilter">
          {this.state.countrys.map(contry => {
              return <option >{contry}</option>
          })}
      </select>)
        }
      }

      showAges = () => {
        if (this.state.filterSelect === "AllAge") {
            return (<select className="form-control minimal" value={this.state.ageSelect} onChange={this.HandleAgeChange} id="countySelectFilter">
            {this.state.ages.map(age => {
                return <option >{age}</option>
            })}
            <option >Comparision</option>
        </select>)
          }
      }

      showSexs = () => {
        if (this.state.filterSelect === "AllSex") {
            return (<select className="form-control minimal" value={this.state.sexSelect} onChange={this.HandleSexChange} id="countySelectFilter">
            {this.state.sexs.map(sex => {
                return <option >{sex}</option>
            })}
            <option >Comparision</option>
        </select>)
          }
      }

      closeInfoBox = () => {
        return this.setState({
            infoBoxOpen: false
        })
    }

    showInfoBox = () => {
        if (this.state.infoBoxOpen) {
            return (<div class="row infoBox">
                <div class="col-md-1">
                    <img style={{margin: "auto", display: "block" }} src={clickIcon} id="ClickLogo" alt="Logo" />
                </div>
                <div class="col-md-11 introTextContainer">
                    <b>This is an Interactive Figure</b>< br />
                    Use the tabs below to view data grouped by Sex, Drug Type, then hover over each data point to view precise figures. To remove a country from view, click the coloured box in the legend
                    <button id="introCross" onClick={() => this.closeInfoBox()}> + </button>
                </div>

            </div>)
        }
    }

    closePopup = () =>{
        this.setState({
            isOpenPopup: false
        })
    }
 
    render() {
        return (
            <div className="appContainer" style={{margin: "auto" }}>
                <div class="row textTitle">
                <div class="col-md-0 ">
                {/* <img style={{height: "63px", paddingLeft: "0px", paddingTop: "12px"}} src={quotientIcon} id="quotientLogo" alt="Logo" /> */}
                </div>
                <div class="col-md-12">
                <h2>Crude incidence of hip fracture in patients 50+ years old, 2005-2018</h2>
                </div> 
                
                <div class="col-md-2">
                </div>  
                </div>   
                {this.showInfoBox()}
                    <span className="textTitle"></span>

                <div className="outerButtonContainer">

                    <div className="buttonContainer">
                        <button className={this.state.filterSelect === "All" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("All")}>All</button>
                        <button className={this.state.filterSelect === "AllSex" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("AllSex")}>Sex</button>
                        <button className={this.state.filterSelect === "AllAge" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("AllAge")}>Age Group</button>
                    </div>

                    <div className="DropdownContainer">
                        {this.showAges()}
                        {this.showSexs()}
                        {this.showCountrys()}
                    </div>

                </div>

                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />

<div class="footnote">{"*All patients in US Medicare were 65+ years-old; all patients in Japan data were <=75 years-old"}</div>
<Modal isOpen={this.state.isOpenPopup} centered={true}>
                    <ModalHeader >Graph Tutorial</ModalHeader>
                    <ModalBody>
                    <iframe width="100%" height="500px" src="https://www.youtube-nocookie.com/embed/YjWc4NtNyYk" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; fullscreen" allow allowFullScreen></iframe>
                    </ModalBody>
                    <ModalFooter>
                    <button class="btn btn-primary" onClick={() => this.closePopup()}>Close</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}