import React, { PureComponent } from 'react'
import Chart from "chart.js/auto";
import { lineWithErrorBars } from "chartjs-chart-error-bars"
import { obj } from "../data/5e"
import quotientIcon from '../images/amgen-blue.svg'
import clickIcon from '../images/click.png'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
let myLineChart;

const colors = [

    "#FFEB3C",
    "#17A3E3",
    "#9C28B1",
    "#409442",
    "#FF9700",
    "#019688",
    "#0E4779",
    "#AD1515",
    "#F22C1F",
    "#DE3998",
    "#01BCD5",
    "#5F37AB",
    "#8BC24A",
    "#277429",
    "#CDDC39",
    "#1478AD"

]


export default class LineGraph extends PureComponent {



    chartRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            maindata: {},
            filterSelect: "All",
            countySelect: "",
            countrys: [],
            drugs: [],
            sexs: [],
            sexSelect: "",
            drugSelect: "",
            showCountrys: false,
            treatmentSelect: "",
            treatments: [],
            infoBoxOpen: true,
            isOpenPopup: true
        };
        this.getRandomRgb = this.getRandomRgb.bind(this);
        this.getData = this.getData.bind(this);
    }

    getRandomRgb = () => {
        const num = Math.round(0xffffff * Math.random());
        const r = num >> 16;
        const g = num >> 8 & 255;
        const b = num & 255;
        console.log(lineWithErrorBars);
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';

    }

    getMissing = (a, b) => {
        let missings = [];
        let matches = false;

        for (var i = 0; i < a.length; i++) {
            matches = false;
            for (var e = 0; e < b.length; e++) {
                if (a[i] === b[e]) matches = true;
            }
            if (!matches) missings.push(a[i]);
        }
        return missings;
    }

    getData = () => {
        let dataset_filter = []
        let all_labels_filter = []
        let locations_filter = [];
        let countrys_filter = [];
        let drugs_filter = [];
        let sexs_filter = [];
        let treatments_filter = [];
        let colorCount = 0;

        let newArray = obj.filter(function (el) {
            return el.Sex === "All" && el.Drug === "All" && el.Treatment === "All"
        });


        for (let i = 0; i < newArray.length; i++) {
            if (locations_filter.indexOf(newArray[i].COUN) === -1) {
                locations_filter.push(newArray[i].COUN);
            }
        }

        for (let i = 0; i < newArray.length; i++) {
            if (countrys_filter.indexOf(newArray[i].COUNTRY) === -1) {
                countrys_filter.push(newArray[i].COUNTRY);
            }
        }

        for (let i = 0; i < obj.length; i++) {
            if (drugs_filter.indexOf(obj[i].Drug) === -1) {
                drugs_filter.push(obj[i].Drug);
            }
        }

        for (let i = 0; i < obj.length; i++) {
            if (sexs_filter.indexOf(obj[i].Sex) === -1) {
                sexs_filter.push(obj[i].Sex);
            }
        }

        for (let i = 0; i < obj.length; i++) {
            if (treatments_filter.indexOf(obj[i].Treatment) === -1) {
                treatments_filter.push(obj[i].Treatment);
            }
        }


        for (let i = 0; i < newArray.length; i++) {
            if (all_labels_filter.indexOf(newArray[i].YEAR) === -1) {
                all_labels_filter.push(newArray[i].YEAR);
            }
        }


        locations_filter.forEach(function (location) {
            let labels = [];
            let oos = [];
            let color = colors[colorCount];
            colorCount++;
            newArray.forEach(function (report) {
                let TEMP = {}
                if (report.COUN === location) {
                    TEMP.y = report.DATA
                    TEMP.yMin = report.LOW
                    TEMP.yMax = report.HIGH
                    oos.push(TEMP)
                    labels.push(report.YEAR);
                }
            });

            let missing = this.getMissing(all_labels_filter, labels);

            if (missing) {
                missing.forEach(function (missed) {
                    labels.push(missed);
                    oos.push(0)
                });
            }


            let data = { label: location, data: oos, borderColor: color, backgroundColor: color }

            dataset_filter.push(data);

        }.bind(this));

        this.setState({
            maindata: { labels: all_labels_filter, datasets: dataset_filter },
            countrys: countrys_filter,
            drugs: drugs_filter,
            sexs: sexs_filter,
            treatments: treatments_filter
        })

    }


    filterData = (filter, country) => {

        let dataset_filter = []
        let all_labels_filter = []
        let locations_filter = [];
        let colorCount = 0;

        if (filter === "All") {
            this.setState({
                filterSelect: "All",
                showCountrys: false,
                countySelect: this.state.countrys[0],
                sexSelect: this.state.sexs[0],
                drugSelect: this.state.drugs[0],
                treatmentSelect: this.state.treatments[0],

            })
        } else if (filter === "AllSex") {
            this.setState({
                filterSelect: "AllSex",
                countySelect: this.state.countrys[0],
                sexSelect: this.state.sexs[0],
                drugSelect: this.state.drugs[0],
                treatmentSelect: this.state.treatments[0],
                showCountrys: false
            })
        } else if (filter === "AllDrug") {
            this.setState({
                filterSelect: "AllDrug",
                countySelect: this.state.countrys[0],
                sexSelect: this.state.sexs[0],
                drugSelect: this.state.drugs[0],
                treatmentSelect: this.state.treatments[0],
                showCountrys: false
            })
        } else if (filter === "AllTreatment") {
            this.setState({
                filterSelect: "AllTreatment",
                countySelect: this.state.countrys[0],
                sexSelect: this.state.sexs[0],
                drugSelect: this.state.drugs[0],
                treatmentSelect: this.state.treatments[0],
                showCountrys: false
            })
        } else if (country === "Comparision") {
            if (country == null) {
                this.setState({
                    countySelect: this.state.countrys[0]
                })
            }

        }

        let newArray = obj.filter(function (el) {
            if (filter === "All" || filter === "AllSex" || filter === "AllDrug" || filter === "AllTreatment") {
                return el.Sex === "All" && el.Drug === "All" && el.Treatment === "All"
            } else if (filter === "Drug") {
                if (country === "Comparision") {
                    return el.COUNTRY === this.state.countrys[0] && el.Sex === "All" && el.Treatment === "All"
                } else {
                    return el.Sex === "All" && el.Drug === country && el.Treatment === "All" && el.Sex === "All"
                }
            } else if (filter === "DrugCountry") {

                return el.Sex === "All" && el.COUNTRY === country && el.Treatment === "All"
            } else if (filter === "Sex") {
                if (country === "Comparision") {
                    return el.COUNTRY === this.state.countrys[0] && el.Drug === "All" && el.Treatment === "All"
                } else {
                    return el.Drug === "All" && el.Sex === country && el.Treatment === "All"
                }
            } else if (filter === "SexCountry") {

                return el.Drug === "All" && el.COUNTRY === country && el.Treatment === "All"
            } else if (filter === "Treatment") {
                if (country === "Comparision") {
                    return el.COUNTRY === this.state.countrys[0] && el.Drug === "All" && el.Sex === "All"
                } else {
                    return el.Drug === "All" && el.Treatment === country && el.Sex === "All"
                }
            } else if (filter === "TreatmentCountry") {

                return el.Drug === "All" && el.COUNTRY === country && el.Sex === "All"
            }
            return null
        }.bind(this));




        for (let i = 0; i < newArray.length; i++) {
            if (locations_filter.indexOf(newArray[i].COUN) === -1) {
                locations_filter.push(newArray[i].COUN);
            }
        }


        for (let i = 0; i < newArray.length; i++) {
            if (all_labels_filter.indexOf(newArray[i].YEAR) === -1) {
                all_labels_filter.push(newArray[i].YEAR);
            }
        }


        locations_filter.forEach(function (location) {
            let labels = [];
            let oos = [];
            let color = colors[colorCount];
            colorCount++;
            newArray.forEach(function (report) {
                let TEMP = {}
                if (report.COUN === location) {
                    TEMP.y = report.DATA
                    TEMP.yMin = report.LOW
                    TEMP.yMax = report.HIGH
                    oos.push(TEMP)
                    labels.push(report.YEAR);
                }
            });

            let missing = this.getMissing(all_labels_filter, labels);

            if (missing) {
                missing.forEach(function (missed) {
                    labels.push(missed);
                    oos.push(0)
                });
            }


            let data = { label: location, data: oos, borderColor: color, backgroundColor: color }

            dataset_filter.push(data);

        }.bind(this));

        this.setState({
            maindata: { labels: all_labels_filter, datasets: dataset_filter }
        })

        // myLineChart.update();
    }

    componentDidMount() {

        this.getData();
        this.buildChart();

    }

    componentDidUpdate() {
        this.buildChart();
    }




    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");

        if (typeof myLineChart !== "undefined") myLineChart.destroy();
        Chart.defaults.font.size = 18;

        myLineChart = new Chart(myChartRef, {
            type: "lineWithErrorBars",
            data: this.state.maindata,
            options: {
                spanGaps: false,
                interaction: {
                    //mode: 'index',
                    intersect: false,
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Year',
                            padding: 5,
                            font: {
                                size: 20,
                                weight: 700
                            }
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: '%',
                            padding: 5,
                            font: {
                                size: 20,
                                weight: 700
                            }
                        }
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            // usePointStyle: true,
                            boxWidth: 40,
                            boxHeight: 17,
                            font: {
                                size: 15,
                                weight: 500
                            },
                            padding: 15
                        }
                    }
                }

            }

        });
    }

    HandleCountryChange = (e) => {

        if (this.state.filterSelect === "AllDrug") {
            this.setState({
                countySelect: e.target.value
            }, () => this.filterData("DrugCountry", e.target.value))
        } else if (this.state.filterSelect === "AllSex") {
            this.setState({
                countySelect: e.target.value
            }, () => this.filterData("SexCountry", e.target.value))
        } else if (this.state.filterSelect === "AllTreatment") {
            this.setState({
                countySelect: e.target.value
            }, () => this.filterData("TreatmentCountry", e.target.value))
        }

    }

    HandleDrugChange = (e) => {

        console.log(e.target.value)

        if (e.target.value === "Comparision") {

            this.setState({
                drugSelect: e.target.value,
                showCountrys: true,
                countySelect: this.state.countrys[0]

            }, () => this.filterData("Drug", "Comparision"))

        } else {
            this.setState({
                drugSelect: e.target.value,
                showCountrys: false,
                countySelect: this.state.countrys[0]
            }, () => this.filterData("Drug", e.target.value))
        }


    }

    HandleSexChange = (e) => {

        if (e.target.value === "Comparision") {

            this.setState({
                sexSelect: e.target.value,
                showCountrys: true,
                countySelect: this.state.countrys[0]

            }, () => this.filterData("Sex", "Comparision"))

        } else {
            this.setState({
                sexSelect: e.target.value,
                showCountrys: false,
                countySelect: this.state.countrys[0]
            }, () => this.filterData("Sex", e.target.value))
        }


    }

    HandleTreatmentChange = (e) => {

        if (e.target.value === "Comparision") {

            this.setState({
                treatmentSelect: e.target.value,
                showCountrys: true,
                countySelect: this.state.countrys[0]

            }, () => this.filterData("Treatment", "Comparision"))

        } else {
            this.setState({
                treatmentSelect: e.target.value,
                showCountrys: false,
                countySelect: this.state.countrys[0]
            }, () => this.filterData("Treatment", e.target.value))
        }


    }

    showCountrys = () => {
        if (this.state.showCountrys === true) {
            return (<select className="form-control minimal" value={this.state.countySelect} onChange={this.HandleCountryChange} id="countySelectFilter">
                {this.state.countrys.map(contry => {
                    return <option >{contry}</option>
                })}
            </select>)
        }
    }

    showDrugs = () => {
        if (this.state.filterSelect === "AllDrug") {
            return (<select className="form-control minimal" value={this.state.drugSelect} onChange={this.HandleDrugChange} id="countySelectFilter">
                <option value="All">All</option>
                <option value="DEN">denosumab</option>
                <option value="IBP">IV bisphosphonates</option>
                <option value="OBP">oral bisphosphonates</option>
                <option value="OTH">others: calcitnonin, strontium ranelate, raloxifene, hormone replacement therapy</option>
                <option value="PTH">parathyroid hormone analogues</option>
                <option value="Comparision">Comparision</option>
            </select>)
        }
    }

    showSexs = () => {
        if (this.state.filterSelect === "AllSex") {
            return (<select className="form-control minimal" value={this.state.sexSelect} onChange={this.HandleSexChange} id="countySelectFilter">
                {this.state.sexs.map(sex => {
                    return <option >{sex}</option>
                })}
                <option >Comparision</option>
            </select>)
        }
    }

    showTreatments = () => {
        if (this.state.filterSelect === "AllTreatment") {
            return (<select className="form-control minimal" value={this.state.treatmentSelect} onChange={this.HandleTreatmentChange} id="countySelectFilter">
                {this.state.treatments.map(treatment => {
                    return <option >{treatment}</option>
                })}
                <option >Comparision</option>
            </select>)
        }
    }

    closeInfoBox = () => {
        return this.setState({
            infoBoxOpen: false
        })
    }

    showInfoBox = () => {
        if (this.state.infoBoxOpen !== false) {
            return (<div className="row infoBox">
                <div class="col-md-1">
                    <img style={{ margin: "auto", display: "block" }} src={clickIcon} id="ClickLogo" alt="Logo" />
                </div>
                <div className="col-md-11 introTextContainer">
                    <b>This is an Interactive Figure</b>< br />
                    Use the tabs below to view data grouped by Sex, Drug Type, then hover over each data point to view precise figures. To remove a country from view, click the coloured box in the legend
                    <button id="introCross" onClick={() => this.closeInfoBox()}> + </button>
                </div>

            </div>)
        }
    }

    closePopup = () =>{
        this.setState({
            isOpenPopup: false
        })
    }

    render() {
        return (
            <div className="appContainer" style={{ margin: "auto" }}>
                <div class="row textTitle">
                    <div class="col-md-0 ">
                        {/* <img style={{height: "63px", paddingLeft: "0px", paddingTop: "12px"}} src={quotientIcon} id="quotientLogo" alt="Logo" /> */}
                    </div>
                    <div class="col-md-12">
                        <h2>Pharmacological treatment rate within 1 year of hip fracture, 2005-2018</h2>
                    </div>

                    <div class="col-md-2">
                    </div>
                </div>

                {this.showInfoBox()}
                
                <div className="outerButtonContainer">

                    <div className="buttonContainer">
                        <button className={this.state.filterSelect === "All" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("All")}>All</button>
                        <button className={this.state.filterSelect === "AllSex" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("AllSex")}>Sex</button>
                        <button className={this.state.filterSelect === "AllDrug" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("AllDrug")}>Drug Type</button>
                        <button className={this.state.filterSelect === "AllTreatment" ? 'btn btn-primary active' : 'btn btn-primary'} onClick={() => this.filterData("AllTreatment")}>Treatment Experience</button>
                    </div>

                    <div className="DropdownContainer">
                        {this.showDrugs()}
                        {this.showSexs()}
                        {this.showTreatments()}
                        {this.showCountrys()}

                    </div>

                </div>

                <canvas
                    id="myChart6"
                    ref={this.chartRef}
                />

                {/* <div class="footnote"><span class="drugNames"><b>DEN:</b> denosumab</span> <span class="drugNames"><b>IBP:</b> IV bisphosphonates</span> <span class="drugNames"><b>OBP:</b> oral bisphosphonates</span> <span class="drugNames"><b>OTH:</b> other</span> <span class="drugNames"><b>PTH:</b> parathyroid hormone analogues</span> < br /> < br /><span class="drugNames"><b>The other category consists of:</b> Calcitonin, Strontium ranelete, Raloxifene and Hormone replacement therapy</span></div> */}
                <Modal isOpen={this.state.isOpenPopup} centered={true}>
                    <ModalHeader >Graph Tutorial</ModalHeader>
                    <ModalBody>
                    <iframe width="100%" height="500px" src="https://www.youtube-nocookie.com/embed/YjWc4NtNyYk" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; fullscreen" allow allowFullScreen></iframe>
                    </ModalBody>
                    <ModalFooter>
                    <button class="btn btn-primary" onClick={() => this.closePopup()}>Close</button>
                    </ModalFooter>
                </Modal>
            
            </div>
        )
    }
}