import './App.css';
import Chart3a from './Charts/3a'
import Chart5e from './Charts/5e'
import Chart4a from './Charts/4a'
import Chart8c from './Charts/8c'
import "./bootstrap.min.css"

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// import ReactGA from 'react-ga4';
// import { createBrowserHistory } from 'history';

// const trackingId = "G-21K3FJD44D";
// ReactGA.initialize(trackingId);

// const history = createBrowserHistory();

// history.listen(location => {
//   ReactGA.send({ hitType: "pageview", page: location.pathname });
// });

function App() {


  return (

    <div className="App">
      <Router>
        <Switch>
        <Route path="/3a">
          <Chart3a />
          </Route>
          <Route path="/4a">
          <Chart4a />
          </Route>
          <Route path="/5e">
          <Chart5e />
          </Route>
          <Route path="/8c">
          <Chart8c />
          </Route>
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;